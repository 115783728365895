@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

.modal-content{
    border: none;
    background: transparent;
}
.modal-header .btn-close{
  background-color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.card{
    width: auto;
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 20px;
}

.card h2{
  margin-bottom: 10px;
}

.card a.fp{
  width: 100%;
  display: flex;
  color: #5881D0;
}

.login_register{
  display: flex;
  width: 100%;
  border: 1px solid rgba(221, 221, 221, 1);
  border-radius: 15px;
  margin: 20px 0;
}

.login_register a{
  font-size: 1em;
  padding: 10px 55px;
  border: none;
  width: 50%;
}

.login{
  border-radius: 15px;
  color: black;
}
.login_active{
  border-radius: 15px;
  background: linear-gradient(90deg, #FFF703, #d1ca03) !important;
  color: black !important;
}

.register{
  border-radius: 15px;
  color: black !important;
}

.form{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form input{
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
  color: #A0A6A3;
  font-family: "Roboto Mono", sans-serif;
  box-shadow: 1px 5px 9px rgba(211, 211, 211, .7);
}

.form input.email{
  margin-bottom: 15px;
}

.form input.pass{
  margin-bottom: 15px;
}

.form input.comfirm_pass{
  margin-bottom: 5px;
}

.login_btn{
  font-size: 20px;
  color: white;
  border-radius: 15px;
  border: none;
  background-color: #FFF703;
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  background:  linear-gradient(90deg, #FFF703, #d1ca03);
  box-shadow: 1px 5px 9px rgba(211, 211, 211, .9);
}
